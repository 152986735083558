import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { FilterModel } from 'models/FilterModel';
import { ProductModel } from 'models/ProductModel';
import { ProductModelDB } from 'models/dao/ProductModelDB';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
});

const useProductsService = () => {
    const { getToken } = useAuth();

    /**
     * Format a list of products from the database model to our custom model.
     * 
     * @param {ProductModelDB[]} productList - The productList from the database to format to our custom model.
     * @returns {ProductModel[]} - The list of products formatted.
     */
    const formatProduct = (productList: ProductModelDB[]): ProductModel[] => {
        const formatted: ProductModel[] = [];
        productList.forEach(product => {
            let formattedElement = {
                image: product.imageUrl,
                price: product.price,
                minimum: product.sellingUnit,
                productId: product.id,
                productName: product.name,
                stock: product.stock,
                unitFormat: product.unitFormat,
                selected: true,
                userId: product.userId,
                description: product.description,
                category: product.category,
                taxRate: product.taxRate,
                isAvailable: true, // ! Update after backend is fixed
                quantity: 0
            }
            formatted.push(formattedElement);
        });
        return formatted;
    };

    /**
     * Fetch from the DB and return a Product list.
     * 
     * @returns {ProductModel[]} - The list of products from the database, formatted for the frontend.
     */
    const getAllProducts = async (): Promise<ProductModel[]> => {
        try {
            const token = await getToken();
            const response = await api.get('/products', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const formatted = formatProduct(response.data.records)
            return formatted;
        } catch (error) {
            throw error;
        }
    };

    /**
     * Fetch from the DB and return a Product object from an ID.
     * 
     * @param {string} productId - The ID of the product to be search for
     * @returns {ProductModel} - The product found with the corresponding ID.
     */
    const getProductById = async ({ productId }: { productId: string }): Promise<ProductModel> => {
        try {
            const token = await getToken();
            const response = await api.get(`/products/${productId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // Transform response to ProductModel
            const transformedProduct = {
                image: response.data.product.imageUrl,
                price: response.data.product.price,
                minimum: response.data.product.sellingUnit,
                productId: response.data.product.id,
                productName: response.data.product.name,
                stock: response.data.product.stock,
                unitFormat: response.data.product.unitFormat,
                selected: true,
                userId: response.data.product.userId,
                description: response.data.product.description,
                category: response.data.product.category,
                taxRate: response.data.product.taxRate,
                isAvailable: true, // ! Update after backend is fixed
                quantity: 0
            }
            return transformedProduct;
        } catch (error) {
            throw error;
        }
    };

    /**
     * Filters the products based on the provided filters.
     * 
     * @param {ProductModel[]} products - The list of products to filter.
     * @param {FilterModel[]} filters - The filters to apply.
     * @returns {Promise<ProductModel[]>} - The filtered list of products.
     */
    const getFilteredProducts = async (
        products: ProductModel[],
        filters: FilterModel[]
    ): Promise<ProductModel[]> => {
        try {
            let filteredProducts = products.filter(product => {
                return filters.every(filter => {
                    switch (filter.typeFilter) {
                        case 'category':
                            if (filter.value === "all") return true;
                            else return product.category === filter.value;
                        // TODO: autres filtres comme prix max, etc.
                        default:
                            return true;
                    }
                });
            });

            // Get filters
            const priceSort = filters.find(filter => filter.typeFilter === 'sortPrice')?.value;
            const nameSort = filters.find(filter => filter.typeFilter === 'sortName')?.value;
            const producerSort = filters.find(filter => filter.typeFilter === 'sortProducer')?.value;

            // Filter by name
            if (nameSort) {
                filteredProducts = filteredProducts.sort((a, b) =>
                    nameSort === 'asc'
                        ? a.productName.localeCompare(b.productName)
                        : b.productName.localeCompare(a.productName)
                );
            }

            // Filter by price
            if (priceSort) {
                filteredProducts = filteredProducts.sort((a, b) =>
                    priceSort === 'desc' ? a.price - b.price : b.price - a.price
                );
            }

            // Filter by producers
            if (producerSort) {
                filteredProducts = filteredProducts.filter((product) => product.userId === producerSort);
            }

            return filteredProducts;
        } catch (error) {
            throw error;
        }
    };

    return { getAllProducts, getProductById, getFilteredProducts };

}
export default useProductsService;