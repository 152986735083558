import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import MarketPlace from './pages/marketplace/MarketPlace';
import Checkout from 'pages/marketplace/Checkout';
import NotFound from './pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import AllProducts from 'pages/marketplace/AllProducts';
import SignUp from 'pages/SignUp';
import SignInPerso from 'pages/SignIn';
import Orders from 'pages/dashboard/Orders';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import SendFiles from 'pages/dashboard/SendFiles';
import MarketplaceLayout from 'layouts/marketplace/MarketplaceLayout';
import SendAddress from 'pages/dashboard/SendAddress';
import MarketHome from 'pages/marketplace/MarketHome';

const App: React.FC = () => {

  return (
    // @ts-ignore
    <Router>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route path="commandes" element={
              <ProtectedRoute userRole={['buyer', 'producer']}>
                <Orders />
              </ProtectedRoute>
            } />
            <Route path="mercuriale" element={
              <ProtectedRoute userRole={['buyer', 'producer']}>
                <SendFiles />
              </ProtectedRoute>
            } />
            <Route path="adresse" element={
              <ProtectedRoute userRole={['buyer', 'producer']}>
                <SendAddress />
              </ProtectedRoute>
            } />
          </Route>

          {/* Marketplace routes for buyers */}
          <Route path="boutique" element={<MarketplaceLayout />}>
            <Route index element={<MarketHome />} />
            <Route path=":organizationId" element={<MarketPlace />} />
            <Route path="checkout" element={
              <ProtectedRoute userRole={['buyer', 'producer']}>
                <Checkout />
              </ProtectedRoute>
            } />
            <Route path=":organizationId/produits" element={<AllProducts />} />
          </Route>
        </Route>

        {/* Other independent routes */}
        <Route path="/inscription" element={<SignUp />} />
        <Route path="/connexion" element={<SignInPerso />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;