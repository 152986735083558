import React, { useEffect, useState } from 'react';
import { Button } from 'components/ui/button';
import { ProductModel } from 'models/ProductModel';
import useProductsService from 'api/products/ProductService';
import { useCart } from 'services/CartService';
import { CircleMinus, CirclePlus } from 'lucide-react';

interface ProductDetailProps {
  idProduct: string;
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProductDetailModal: React.FC<ProductDetailProps> = ({ idProduct, open, onClose }) => {
  const [product, setProduct] = useState<ProductModel | null>(null);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const { addToCart, removeFromCart, getCart } = useCart();
  const { getProductById } = useProductsService();

  useEffect(() => {
    const cart = getCart();
    const productFromCart = cart.find(item => item.productId === product?.productId);
    setQuantity(productFromCart ? productFromCart.quantity : 0);
  }, [product?.productId, getCart]);

  const handleAddToCart = () => {
    if (product?.productId) {
      addToCart(product.productId, 1, true);
      setQuantity(prev => prev + 1);
    }
  };

  const handleRemoveFromCart = () => {
    if (product?.productId && quantity > 0) {
      removeFromCart(product.productId, 1);
      setQuantity(prev => prev - 1);
    }
  };

  useEffect(() => {
    const getProduct = async () => {
      if (idProduct) {
        setLoading(true);
        const theProduct = await getProductById({ productId: idProduct });
        setProduct(theProduct);
        setLoading(false);
      }
    };

    if (open) {
      getProduct();
    }
  }, [idProduct, open, getProductById]);

  const closeModal = () => {
    setProduct(null);
    setQuantity(0);
    setLoading(false);
    onClose(false);
  }

  if (!open || loading) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={() => onClose(false)}>
      <div className="relative bg-white rounded-lg w-[80%] lg:w-[55%] max-w-2xl" onClick={(e) => e.stopPropagation()}>
        <button
          className="absolute -top-3 -right-3 bg-[#639c6e] text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-[#93c57b] shadow-lg"
          onClick={() => closeModal()}
        >
          &#10005;
        </button>

        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/3 h-56 md:h-1/1 overflow-hidden rounded-t-lg md:rounded-r-none md:rounded-l-lg">
            <img
              src={product?.image}
              alt="alt"
              className="w-full h-full object-cover overflow-hidden"
            />
          </div>

          <div className="md:w-2/3 p-6 flex flex-col justify-between bg-[#f6f6f6] rounded-lg">
            <div>
              <div className="bg-[#93c57b] text-white text-sm rounded px-4 py-1 inline-block mb-4">
                {product?.category || "Autre"}
              </div>
              {product?.labels?.map((label, index) => (
                <div key={index} className="bg-[#93c57b] text-white text-sm rounded px-4 py-1 inline-block mb-4 ml-3">
                  {label}
                </div>
              ))}
              <h2 className="text-xl font-bold mb-4">{product?.productName}</h2>
              <p className="text-gray-700 mb-4 overflow-auto max-h-24" dangerouslySetInnerHTML={{ __html: product?.description || "Pas de description" }} />
              <hr className="mb-4" />
              <div className="text-sm text-gray-500 space-y-1">
                <p>Colis : {product?.minimum}{product?.unitFormat}</p>
                <p>TVA : {product?.taxRate}%</p>
                <p className={`mt-6 ${product?.isAvailable ? "text-gray-500" : "text-red-500"}`}>
                  {product?.isAvailable ? <span className='text-primary'>En stock</span> : <span className='text-accent'>Rupture de stock</span>}
                </p>
              </div>
            </div>

            <div className="mt-6">
              <div className="flex mb-4">
                <span className="text-2xl font-bold">{product?.price.toFixed(2)}€/{product?.unitFormat}</span>
                <span className="text-xl font-bold text-gray-400 ml-5 mt-1">{((product?.price || 0) * (product?.minimum || 0)).toFixed(2)}€</span>
              </div>

              {quantity > 0 ? (
                <div className="flex items-center ml-1">
                  <CircleMinus
                    onClick={handleRemoveFromCart}
                    size={30}
                    className='bg-white text-black cursor-pointer hover:bg-gray-100 rounded-full'
                  />
                  <span className="text-md font-bold mx-2">{quantity}</span>
                  <CirclePlus
                    onClick={handleAddToCart}
                    size={30}
                    className='bg-white text-black cursor-pointer hover:bg-gray-100 rounded-full'
                  />
                </div>
              ) : (
                <Button onClick={handleAddToCart} className="w-full bg-[#639c6e] text-white py-1 rounded-lg hover:bg-[#93c57b]">
                  Ajouter au panier
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailModal;
