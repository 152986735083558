import { RegionModel } from "models/RegionModel";

export const getRegionsAvailable = (): RegionModel[] => {
    const regions = [
        {
            id: "Île-de-France",
            label: "Île-de-France",
            description: "Découvrez les meilleurs produits locaux de la région Île-de-France.",
            imageUrl: "https://zupimages.net/up/24/42/a23v.png",
            groups: [
                {
                    id: "Seine-et-Marne",
                    label: "Seine-et-Marne",
                    description: "Dans la belle campagne de Seine-et-Marne, à quelques dizaines de kilomètres de Paris, 12 producteurs cultivent et produisent de nombreux produits biologiques et de qualité.",
                    imageUrl: "https://zupimages.net/up/24/42/a23v.png",
                    deliveryDays: [
                        {
                            value: "tuesday",
                            label: "mardi"
                        },
                        {
                            value: "friday",
                            label: "vendredi"
                        }
                    ],
                    deliveryScope: "Paris et petite couronne"
                },
            ]
        },
        {
            id: "Occitanie",
            label: "Occitanie",
            description: "Cette région sera disponible prochainement !",
            imageUrl: "https://zupimages.net/up/24/42/a23v.png",
            groups: []
        },
        {
            id: "Auvergne-Rhône-Alpes",
            label: "Auvergne-Rhône-Alpes",
            description: "Cette région sera disponible prochainement !",
            imageUrl: "https://zupimages.net/up/24/42/a23v.png",
            groups: []
        }
    ];
    return regions;
};
